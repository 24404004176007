import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './Login';
import RequireAuth from './RequireAuth';
import Layout from './Layout';
import Inventaire from './Inventaire';
import Vivier from './Vivier'
import { setAuthToken } from './api';
import Mouvements from './Mouvements';
import Lots from './Lots';
import EtatHuitres from './EtatHuitres';
import MovementsList from './MovementsList';

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setAuthToken(token);
      setIsAuthenticated(true);
    }
  }, []);

  const handleLogin = (token) => {
    localStorage.setItem('token', token);
    setAuthToken(token);
    setIsAuthenticated(true);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setAuthToken(null);
    setIsAuthenticated(false);
  };

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login onLogin={handleLogin} />} />
        <Route element={<RequireAuth isAuthenticated={isAuthenticated} />}>
          <Route path="/" element={<Layout handleLogout={handleLogout} />}>
            <Route path="/inventaire" element={<Inventaire />} />
            <Route path="/vivier" element={<Vivier />} />
            <Route path="mouvements/ajouter" element={<Mouvements />} />
            <Route path="mouvements/voir" element={<MovementsList />} />
            <Route path="/lots" element={<Lots />} />
            <Route path="/etat-huitres" element={<EtatHuitres/>} />
          </Route>
        </Route>
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </Router>
  );
};

export default App;
