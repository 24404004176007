import React, { useEffect, useState, useRef } from 'react';
import { fetchInventaire } from './api';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import 'react-toastify/dist/ReactToastify.css';
import './Inventaire.css';

Modal.setAppElement('#root'); // Accessibility

const Inventaire = () => {
  const [inventaire, setInventaire] = useState([]);
  const [groupedInventaire, setGroupedInventaire] = useState({});
  const [selectedRang, setSelectedRang] = useState(null);
  const [typesModalIsOpen, setTypesModalIsOpen] = useState(false);
  const modalRef = useRef(null);
  console.log(selectedRang)
  useEffect(() => {
    const loadData = async () => {
      try {
        // Fetch all inventory ranks, including those without oysters
        const response = await fetchInventaire();

        // Group the response data by numero_rang
        const groupedData = response.data.reduce((acc, current) => {
          const { numero_rang } = current;
          if (!acc[numero_rang]) {
            acc[numero_rang] = [];
          }
          acc[numero_rang].push(current);
          return acc;
        }, {});

        setInventaire(response.data);
        setGroupedInventaire(groupedData);
      } catch (error) {
        console.error('Failed to fetch inventaire:', error);
        toast.error("Échec du chargement de l'inventaire");
      }
    };

    loadData();
  }, []);

  const openTypesModal = (numero_rang) => {
    // Set the selected rank and open the modal
    setSelectedRang(groupedInventaire[numero_rang]);
    setTypesModalIsOpen(true);
  };

  const closeTypesModal = () => {
    setTypesModalIsOpen(false);
    setSelectedRang(null);
  };

  return (
    <div>
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl">Inventaire du parc</h2>
      </div>
      <TransitionGroup className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {Object.keys(groupedInventaire).map((numero_rang, index) => (
          <CSSTransition key={index} timeout={300} classNames="fade">
            <div
              className="p-4 border rounded cursor-pointer hover:bg-gray-100 flex flex-col items-center justify-center"
              onClick={() => openTypesModal(numero_rang)}
            >
              <h3 className="text-lg font-bold">Rang {numero_rang}</h3>
              {/* Check if there are any oysters or pockets present in the rank */}
              {groupedInventaire[numero_rang].some(r => r.lot_nom || r.nombre_poche) ? (
                <div className="text-green-500">Des huîtres ou des poches sont présentes</div>
              ) : (
                <div className="text-red-500">Aucune huître présente</div>
              )}
            </div>
          </CSSTransition>
        ))}
      </TransitionGroup>
      <Modal
        isOpen={typesModalIsOpen}
        onRequestClose={closeTypesModal}
        contentLabel="Détails du Rang"
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <CSSTransition in={typesModalIsOpen} timeout={300} classNames="fade" unmountOnExit nodeRef={modalRef}>
          <div className="p-6 bg-white rounded-lg shadow-lg max-w-md mx-auto" ref={modalRef}>
            <h2 className="text-2xl mb-4">Détails du Rang {selectedRang && selectedRang[0].numero_rang}</h2>
            {selectedRang && selectedRang.length > 0 ? (
              <div className="grid grid-cols-1 gap-4">
                {/* Display details for each lot */}
                {selectedRang.map((rangDetail, idx) => (
                  <div key={idx} className="p-4 border rounded shadow">
                    {/* Afficher les informations du lot même pour les poches */}
                    <p><span className="font-bold">Nom du Lot :</span> {rangDetail.lot_nom || 'N/A'}</p>
                    <p><span className="font-bold">Provenance :</span> {rangDetail.lot_provenance || 'N/A'}</p>
                    
                    {/* Display pocket details if available */}
                    {rangDetail.nombre_poche ? (
                      <>
                        <p><span className="font-bold">Nombre de Poches :</span> {rangDetail.nombre_poche}</p>
                        <p><span className="font-bold">Poids par Poche :</span> {rangDetail.poids_par_poche} kg</p>
                        <p><span className="font-bold">Nombre de Bêtes par Kilo :</span> {rangDetail.nombre_betes_par_kilo}</p>
                      </>
                    ) : (
                      <>
                        <p><span className="font-bold">Type d'huître :</span> {rangDetail.type_huitre || 'N/A'}</p>
                        <p><span className="font-bold">Quantité :</span> {rangDetail.quantite_parc} kg</p>
                      </>
                    )}
                  </div>
                ))}
              </div>
            ) : (
              <div className="text-red-500">Aucune huître ou poche présente dans ce rang</div>
            )}
            <button
              type="button"
              onClick={closeTypesModal}
              className="bg-gray-500 text-white py-2 px-4 rounded hover:bg-gray-600 mt-4"
            >
              Fermer
            </button>
          </div>
        </CSSTransition>
      </Modal>
    </div>
  );
};

export default Inventaire;
