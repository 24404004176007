import React, { useEffect, useState } from 'react';
import { format, parseISO, addDays } from 'date-fns';
import { fr } from 'date-fns/locale';
import { fetchLots, addLot, updateLot, deleteLot, archiveLot, unarchiveLot, fetchTypesByLot } from './api';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Lots.css'; // Assurez-vous d'avoir ce fichier pour les styles de transition

Modal.setAppElement('#root'); // Pour l'accessibilité

const Lots = () => {
  const [lots, setLots] = useState([]);
  const [selectedLot, setSelectedLot] = useState(null);
  const [includeArchived, setIncludeArchived] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [useNumberPerKilo, setUseNumberPerKilo] = useState(true); // Toggle state

  const defaultDate = format(addDays(new Date(), 0), 'yyyy-MM-dd');

  const [formData, setFormData] = useState({
    nom: '',
    provenance: '',
    date_reception: defaultDate,
    nombre_betes_par_kilo: 0,
    type_huitres: [
      { type: '1', poids_initial: 0, quantite_vivier: 0, quantite_marais: 0, quantite_parc: 0, quantite_vente: 0, numero_rang_actuel: 0 },
      { type: '2', poids_initial: 0, quantite_vivier: 0, quantite_marais: 0, quantite_parc: 0, quantite_vente: 0, numero_rang_actuel: 0 },
      { type: '3', poids_initial: 0, quantite_vivier: 0, quantite_marais: 0, quantite_parc: 0, quantite_vente: 0, numero_rang_actuel: 0 },
      { type: '4', poids_initial: 0, quantite_vivier: 0, quantite_marais: 0, quantite_parc: 0, quantite_vente: 0, numero_rang_actuel: 0 },
      { type: '5', poids_initial: 0, quantite_vivier: 0, quantite_marais: 0, quantite_parc: 0, quantite_vente: 0, numero_rang_actuel: 0 }
    ]
  });

  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    const loadData = async () => {
      try {
        const response = await fetchLots(includeArchived);
        const lotsData = Array.isArray(response.data) ? response.data : [];
        for (const lot of lotsData) {
          const typesResponse = await fetchTypesByLot(lot.id);
          lot.type_huitres = Array.isArray(typesResponse.data) ? typesResponse.data : [];
          lot.type_huitres.sort((a, b) => a.type_huitre - b.type_huitre);
        }
        setLots(lotsData);
      } catch (error) {
        console.error('Failed to fetch lots:', error);
        toast.error('Échec du chargement des lots');
      }
    };

    loadData();
  }, [includeArchived]);

  const openModal = (lot = null) => {
    setSelectedLot(lot);
    if (lot) {
      setFormData({
        nom: lot.nom,
        provenance: lot.provenance || '',
        date_reception: format(parseISO(lot.date_reception), 'yyyy-MM-dd'),
        nombre_betes_par_kilo: lot.nombre_betes_par_kilo,
        type_huitres: lot.type_huitres.map(t => ({
          type: t.type_huitre,
          poids_initial: t.poids_initial,
          quantite_vivier: t.quantite_vivier,
          quantite_marais: t.quantite_marais,
          quantite_parc: t.quantite_parc,
          quantite_vente: t.quantite_vente,
          numero_rang_actuel: t.numero_rang_actuel
        })).sort((a, b) => a.type - b.type)
      });
    } else {
      setFormData({
        nom: '',
        provenance: '',
        date_reception: defaultDate,
        nombre_betes_par_kilo: 0,
        type_huitres: [
          { type: '1', poids_initial: 0, quantite_vivier: 0, quantite_marais: 0, quantite_parc: 0, quantite_vente: 0, numero_rang_actuel: 0 },
          { type: '2', poids_initial: 0, quantite_vivier: 0, quantite_marais: 0, quantite_parc: 0, quantite_vente: 0, numero_rang_actuel: 0 },
          { type: '3', poids_initial: 0, quantite_vivier: 0, quantite_marais: 0, quantite_parc: 0, quantite_vente: 0, numero_rang_actuel: 0 },
          { type: '4', poids_initial: 0, quantite_vivier: 0, quantite_marais: 0, quantite_parc: 0, quantite_vente: 0, numero_rang_actuel: 0 },
          { type: '5', poids_initial: 0, quantite_vivier: 0, quantite_marais: 0, quantite_parc: 0, quantite_vente: 0, numero_rang_actuel: 0 }
        ]
      });
    }
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedLot(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name.startsWith('type_huitres')) {
      const index = parseInt(name.split('[')[1].split(']')[0]);
      const key = name.split(']')[1].substr(1);
      const newTypeHuitres = [...formData.type_huitres];
      newTypeHuitres[index][key] = value;
      setFormData({
        ...formData,
        type_huitres: newTypeHuitres,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (selectedLot) {
        await updateLot(selectedLot.id, formData);
        toast.success(`Le lot ${formData.nom} a été modifié avec succès`);
      } else {
        console.log(formData);
        await addLot(formData);
        toast.success(`Le lot ${formData.nom} a été ajouté avec succès`);
      }
      const response = await fetchLots(includeArchived);
      const lotsData = Array.isArray(response.data) ? response.data : [];
      for (const lot of lotsData) {
        const typesResponse = await fetchTypesByLot(lot.id);
        lot.type_huitres = Array.isArray(typesResponse.data) ? typesResponse.data : [];
        lot.type_huitres.sort((a, b) => a.type_huitre - b.type_huitre);
      }
      setLots(lotsData);
      closeModal();
    } catch (error) {
      console.error('Failed to save lot:', error);
      toast.error('Échec de la sauvegarde du lot');
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer ce lot?')) {
      try {
        await deleteLot(id);
        const response = await fetchLots(includeArchived);
        const lotsData = Array.isArray(response.data) ? response.data : [];
        setLots(lotsData);
        toast.success(`Le lot a été supprimé avec succès`);
      } catch (error) {
        console.error('Failed to delete lot:', error);
        toast.error('Échec de la suppression du lot');
      }
    }
  };

  const handleArchive = async (id) => {
    if (window.confirm('Êtes-vous sûr de vouloir archiver ce lot?')) {
      try {
        await archiveLot(id);
        const response = await fetchLots(includeArchived);
        const lotsData = Array.isArray(response.data) ? response.data : [];
        setLots(lotsData);
        toast.success(`Le lot a été archivé avec succès`);
      } catch (error) {
        console.error('Failed to archive lot:', error);
        toast.error('Échec de l\'archivage du lot');
      }
    }
  };

  const handleUnarchive = async (id) => {
    if (window.confirm('Êtes-vous sûr de vouloir désarchiver ce lot?')) {
      try {
        await unarchiveLot(id);
        const response = await fetchLots(includeArchived);
        const lotsData = Array.isArray(response.data) ? response.data : [];
        setLots(lotsData);
        toast.success(`Le lot a été désarchivé avec succès`);
      } catch (error) {
        console.error('Failed to unarchive lot:', error);
        toast.error('Échec du désarchivage du lot');
      }
    }
  };

  const filteredLots = lots.filter(lot =>
    lot.nom.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div>
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-semibold text-primary-dark">Lots d'huitres</h2>
        <div className="flex space-x-4">
          <input 
            type="text"
            placeholder="Rechercher..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="px-3 py-2 border rounded"
          />
          <button 
            className="bg-primary-green text-white py-2 px-4 rounded hover:bg-green-600"
            onClick={() => setIncludeArchived(!includeArchived)}
          >
            {includeArchived ? 'Masquer les archivés' : 'Afficher les archivés'}
          </button>
          <button 
            className="bg-primary-green text-white py-2 px-4 rounded hover:bg-green-600"
            onClick={() => openModal()}
          >
            Ajouter
          </button>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {filteredLots.map(lot => (
          <div key={lot.id} className="p-4 border rounded shadow-lg space-y-2 bg-white">
            <div className="flex flex-col justify-between">
              <h3 className="text-lg font-bold text-primary-dark mb-2">{lot.nom}</h3>
              <p className="mb-1"><span className="font-semibold">Date de réception:</span> {format(parseISO(lot.date_reception), 'dd/MM/yyyy', { locale: fr })}</p>
              <p className="mb-1"><span className="font-semibold">Provenance:</span> {lot.provenance}</p>
              <p className="mb-1"><span className="font-semibold">Nombre de bêtes par kilo:</span> {lot.nombre_betes_par_kilo}</p>
              <div className="flex space-x-2 mt-2">
                <button 
                  className="bg-blue-500 text-white py-1 px-3 rounded hover:bg-blue-600"
                  onClick={() => openModal(lot)}
                >
                  Modifier
                </button>
                <button 
                  className="bg-red-500 text-white py-1 px-3 rounded hover:bg-red-600"
                  onClick={() => handleDelete(lot.id)}
                >
                  Supprimer
                </button>
                {lot.archived ? (
                  <button 
                    className="bg-yellow-500 text-white py-1 px-3 rounded hover:bg-yellow-600"
                    onClick={() => handleUnarchive(lot.id)}
                  >
                    Désarchiver
                  </button>
                ) : (
                  <button 
                    className="bg-yellow-500 text-white py-1 px-3 rounded hover:bg-yellow-600"
                    onClick={() => handleArchive(lot.id)}
                  >
                    Archiver
                  </button>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Modifier le lot"
        className={`modal-content ${modalIsOpen ? 'fade-in' : 'fade-out'}`}
        overlayClassName={`modal-overlay ${modalIsOpen ? 'fade-in' : 'fade-out'}`}
        style={{
          content: {
            maxHeight: '90vh',
            overflowY: 'auto',
            maxWidth: '50vw',
            width:'50vw',
          },
        }}
      >
        <div className="p-6 bg-white rounded-lg shadow-lg max-w-6xl mx-auto">
          <h2 className="text-2xl mb-4 font-semibold text-primary-dark">{selectedLot ? `Modifier le lot du ${format(parseISO(formData.date_reception), 'dd/MM/yyyy', { locale: fr })}` : 'Ajouter un nouveau lot'}</h2>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label className="block text-gray-700">Nom</label>
              <input
                type="text"
                name="nom"
                value={formData.nom}
                onChange={handleInputChange}
                className="w-full px-3 py-2 border rounded"
              />
            </div>
            <div>
              <label className="block text-gray-700">Provenance</label>
              <input
                type="text"
                name="provenance"
                value={formData.provenance}
                onChange={handleInputChange}
                className="w-full px-3 py-2 border rounded"
              />
            </div>
            <div>
              <label className="block text-gray-700">Date de réception</label>
              <input
                type="date"
                name="date_reception"
                value={formData.date_reception}
                onChange={handleInputChange}
                className="w-full px-3 py-2 border rounded"
              />
            </div>
            <button
              type="button"
              onClick={() => setUseNumberPerKilo(!useNumberPerKilo)}
              className="bg-gray-500 text-white py-2 px-4 rounded hover:bg-gray-600"
            >
              {useNumberPerKilo ? 'Utiliser les Types' : 'Utiliser Nombre de Bêtes par Kilo'}
            </button>
            {useNumberPerKilo ? (
              <div>
                <label className="block text-gray-700">Nombre de bêtes par kilo</label>
                <input
                  type="number"
                  name="nombre_betes_par_kilo"
                  value={formData.nombre_betes_par_kilo}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border rounded"
                />
              </div>
            ) : (
              <div>
                <h4 className="text-lg font-semibold text-primary-dark">Quantités par lieu</h4>
                <table className="min-w-full table-auto">
                  <thead>
                    <tr className="bg-gray-200">
                      <th className="px-4 py-2">Type</th>
                      <th className="px-4 py-2">Quantité Vivier</th>
                      <th className="px-4 py-2">Quantité Marais</th>
                      <th className="px-4 py-2">Quantité Parc</th>
                      <th className="px-4 py-2">Quantité Vente</th>
                      <th className="px-4 py-2">Numéro de Rang</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formData.type_huitres.map((type_huitre, index) => (
                      <tr key={index} className="text-center">
                        <td className="px-4 py-2">{type_huitre.type}</td>
                        <td className="px-4 py-2">
                          <input
                            type="number"
                            name={`type_huitres[${index}].quantite_vivier`}
                            value={type_huitre.quantite_vivier || 0}
                            onChange={handleInputChange}
                            className="w-full px-2 py-1 border rounded"
                          />
                        </td>
                        <td className="px-4 py-2">
                          <input
                            type="number"
                            name={`type_huitres[${index}].quantite_marais`}
                            value={type_huitre.quantite_marais || 0}
                            onChange={handleInputChange}
                            className="w-full px-2 py-1 border rounded"
                          />
                        </td>
                        <td className="px-4 py-2">
                          <input
                            type="number"
                            name={`type_huitres[${index}].quantite_parc`}
                            value={type_huitre.quantite_parc || 0}
                            onChange={handleInputChange}
                            className="w-full px-2 py-1 border rounded"
                          />
                        </td>
                        <td className="px-4 py-2">
                          <input
                            type="number"
                            name={`type_huitres[${index}].quantite_vente`}
                            value={type_huitre.quantite_vente || 0}
                            onChange={handleInputChange}
                            className="w-full px-2 py-1 border rounded"
                          />
                        </td>
                        <td className="px-4 py-2">
                          <input
                            type="number"
                            name={`type_huitres[${index}].numero_rang_actuel`}
                            value={type_huitre.numero_rang_actuel || ''}
                            onChange={handleInputChange}
                            className="w-full px-2 py-1 border rounded"
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
            <div className="flex justify-end space-x-4 mt-4">
              <button 
                type="submit"
                className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
              >
                {selectedLot ? 'Enregistrer' : 'Ajouter'}
              </button>
              <button 
                type="button"
                onClick={closeModal}
                className="bg-gray-500 text-white py-2 px-4 rounded hover:bg-gray-600"
              >
                Annuler
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default Lots;
