import React, { useEffect, useState } from 'react';
import { fetchLots, addMouvement, fetchInventaire, fetchTypesByLot, fetchTypesInRang } from './api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Mouvements = () => {
  const [lots, setLots] = useState([]);
  const [types, setTypes] = useState([]);
  const [formData, setFormData] = useState({
    lot_id: '',
    type_lot_id: '',
    date_mouvement: new Date().toISOString().split('T')[0],
    lieu_from: '',
    lieu_to: '',
    quantite: '',
    type_huitre: '',
    numero_rang_from: '',
    numero_rang_to: '',
    vente_acheteur_from: '',
    vente_acheteur_to: '',
    nombre_poche: '',
    poids_par_poche: '',
    nombre_betes_par_kilo: '',
  });
  const [inventaire, setInventaire] = useState([]);
  const [formLocked, setFormLocked] = useState(false);
  const [mode, setMode] = useState('type'); // 'type' or 'poches'

  useEffect(() => {
    const loadInventaire = async () => {
      try {
        const response = await fetchInventaire();
        setInventaire(response.data);
      } catch (error) {
        console.error('Failed to fetch inventaire:', error);
        toast.error('Échec du chargement de l\'inventaire');
      }
    };

    loadInventaire();
  }, []);

  useEffect(() => {
    const loadLots = async () => {
      try {
        const response = await fetchLots(false);
        const lotsData = Array.isArray(response.data) ? response.data : [];
        const filteredLots = lotsData.filter(lot => !lot.archived);
        setLots(filteredLots);
      } catch (error) {
        console.error('Failed to fetch lots:', error);
        toast.error('Échec du chargement des lots');
      }
    };

    loadLots();
  }, [inventaire]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleModeChange = (e) => {
    setMode(e.target.value);
    // Reset specific fields when switching modes
    setFormData({
      ...formData,
      quantite: '',
      nombre_poche: '',
      poids_par_poche: '',
      nombre_betes_par_kilo: '',
    });
  };

  const handleLotChange = async (e) => {
    if (formLocked) return;
    const lotId = e.target.value;
    const response = await fetchTypesByLot(lotId);
    setFormData({ ...formData, lot_id: lotId, type_huitre: '', type_lot_id: '' });
    setTypes(response.data);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.lieu_from || !formData.lieu_to ||
      ((mode === 'type' && !formData.quantite) ||
      (mode === 'poches' && (!formData.lot_id || !formData.nombre_poche || !formData.poids_par_poche || !formData.nombre_betes_par_kilo))) ||
      (formData.lieu_from === 'Parc' && !formData.numero_rang_from) || 
      (formData.lieu_to === 'Parc' && !formData.numero_rang_to) ||
      (formData.lieu_from === 'Vente' && !formData.vente_acheteur_from) ||
      (formData.lieu_to === 'Vente' && !formData.vente_acheteur_to)) {
      toast.error('Veuillez remplir tous les champs obligatoires.');
      return;
    }

    try {
      await addMouvement(formData);
      toast.success('Déplacement ajouté avec succès');
      setFormData({
        lot_id: '',
        type_lot_id: '',
        date_mouvement: new Date().toISOString().split('T')[0],
        lieu_from: '',
        lieu_to: '',
        quantite: '',
        type_huitre: '',
        numero_rang_from: '',
        numero_rang_to: '',
        vente_acheteur_from: '',
        vente_acheteur_to: '',
        nombre_poche: '',
        poids_par_poche: '',
        nombre_betes_par_kilo: '',
      });
      setFormLocked(false);
      setTypes([]);
    } catch (error) {
      console.error('Failed to add mouvement:', error);
      toast.error('Échec de la création du déplacement !');
    }
  };

  const isFormReady = formData.lieu_from && formData.lieu_to;

  return (
    <div>
      <h2 className="text-2xl mb-4">Ajouter un déplacement d'huitres</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        {/* Toggle between modes */}
        <div>
          <label className="block text-gray-700">Mode d'entrée</label>
          <select
            name="mode"
            value={mode}
            onChange={handleModeChange}
            className="w-full px-3 py-2 border rounded"
          >
            <option value="type">Type d'huitres</option>
            <option value="poches">Poches</option>
          </select>
        </div>
        {/* Common Fields */}
        <div>
          <label className="block text-gray-700">Lieu de départ</label>
          <select
            name="lieu_from"
            value={formData.lieu_from}
            onChange={(e) => handleInputChange(e)}
            className="w-full px-3 py-2 border rounded"
            disabled={formLocked}
          >
            <option value="">Sélectionner un lieu de départ</option>
            <option value="Vivier">Vivier</option>
            <option value="Marais">Marais</option>
            <option value="Parc">Parc</option>
            <option value="Vente">Vente</option>
          </select>
        </div>
        {formData.lieu_from === 'Parc' && (
          <div>
            <label className="block text-gray-700">Numéro de Rang (Départ)</label>
            <select
              name="numero_rang_from"
              value={formData.numero_rang_from}
              onChange={handleInputChange}
              className="w-full px-3 py-2 border rounded"
              disabled={formLocked}
            >
              <option value="">Sélectionner un numéro de rang</option>
              {inventaire.map(rang => (
                <option key={rang.numero_rang} value={rang.numero_rang}>{rang.numero_rang}</option>
              ))}
            </select>
          </div>
        )}
        <div>
          <label className="block text-gray-700">Lieu d'arrivée</label>
          <select
            name="lieu_to"
            value={formData.lieu_to}
            onChange={(e) => handleInputChange(e)}
            className="w-full px-3 py-2 border rounded"
            disabled={formLocked}
          >
            <option value="">Sélectionner un lieu d'arrivée</option>
            <option value="Vivier">Vivier</option>
            <option value="Marais">Marais</option>
            <option value="Parc">Parc</option>
            <option value="Vente">Vente</option>
          </select>
        </div>
        {formData.lieu_to === 'Parc' && (
          <div>
            <label className="block text-gray-700">Numéro de Rang (Arrivée)</label>
            <select
              name="numero_rang_to"
              value={formData.numero_rang_to}
              onChange={handleInputChange}
              className="w-full px-3 py-2 border rounded"
              disabled={formLocked}
            >
              <option value="">Sélectionner un numéro de rang</option>
              {inventaire.map(rang => (
                <option key={rang.numero_rang} value={rang.numero_rang}>{rang.numero_rang}</option>
              ))}
            </select>
          </div>
        )}
        {formData.lieu_from === 'Vente' && (
          <div>
            <label className="block text-gray-700">Acheteur (Départ)</label>
            <select
              name="vente_acheteur_from"
              value={formData.vente_acheteur_from}
              onChange={(e) => handleInputChange(e)}
              className="w-full px-3 py-2 border rounded"
              disabled={formLocked}
            >
              <option value="">Sélectionner un acheteur</option>
              <option value="Restaurant">Restaurant</option>
              <option value="Vente Internet">Vente Internet</option>
              <option value="Dégustation">Dégustation</option>
              <option value="Marché">Marché</option>
            </select>
          </div>
        )}
        {formData.lieu_to === 'Vente' && (
          <div>
            <label className="block text-gray-700">Acheteur (Arrivée)</label>
            <select
              name="vente_acheteur_to"
              value={formData.vente_acheteur_to}
              onChange={handleInputChange}
              className="w-full px-3 py-2 border rounded"
              disabled={formLocked}
            >
              <option value="">Sélectionner un acheteur</option>
              <option value="Restaurant">Restaurant</option>
              <option value="Vente Internet">Vente Internet</option>
              <option value="Dégustation">Dégustation</option>
              <option value="Marché">Marché</option>
            </select>
          </div>
        )}
        <div>
          <label className="block text-gray-700">Date du déplacement</label>
          <input
            type="date"
            name="date_mouvement"
            value={formData.date_mouvement}
            onChange={handleInputChange}
            className="w-full px-3 py-2 border rounded"
            disabled={!isFormReady || formLocked}
          />
        </div>
        {/* Show either 'Type d'huitres' or 'Poches' fields based on mode */}
        {mode === 'type' && (
          <>
            <div>
              <label className="block text-gray-700">Lot</label>
              <select
                name="lot_id"
                value={formData.lot_id}
                onChange={handleLotChange}
                className="w-full px-3 py-2 border rounded"
                disabled={!isFormReady || (formData.lieu_from === 'Parc' && !formData.numero_rang_from) || formLocked}
              >
                <option value="">Sélectionner un lot</option>
                {lots.map(lot => (
                  <option key={lot.id} value={lot.id}>{lot.nom}</option>
                ))}
              </select>
            </div>
            <div>
              <label className="block text-gray-700">Type d'huitres</label>
              <select
                name="type_lot_id"
                value={formData.type_lot_id}
                onChange={(e) => handleInputChange(e)}
                className="w-full px-3 py-2 border rounded"
                disabled={!isFormReady || !formData.lot_id || formLocked}
              >
                <option value="">Sélectionner un type</option>
                {types.map(type => (
                  <option key={type.id} value={type.id}>{type.type_huitre}</option>
                ))}
              </select>
            </div>
            <div>
              <label className="block text-gray-700">Quantité (kg)</label>
              <input
                type="text"
                name="quantite"
                value={formData.quantite}
                onChange={handleInputChange}
                className="w-full px-3 py-2 border rounded"
                disabled={!isFormReady || !formData.type_lot_id || formLocked}
              />
            </div>
          </>
        )}
        {mode === 'poches' && (
          <>
            <div>
              <label className="block text-gray-700">Lot</label>
              <select
                name="lot_id"
                value={formData.lot_id}
                onChange={handleLotChange}
                className="w-full px-3 py-2 border rounded"
                disabled={!isFormReady || formLocked}
              >
                <option value="">Sélectionner un lot</option>
                {lots.map(lot => (
                  <option key={lot.id} value={lot.id}>{lot.nom}</option>
                ))}
              </select>
            </div>
            <div>
              <label className="block text-gray-700">Nombre de poches</label>
              <input
                type="number"
                name="nombre_poche"
                value={formData.nombre_poche}
                onChange={handleInputChange}
                className="w-full px-3 py-2 border rounded"
              />
            </div>
            <div>
              <label className="block text-gray-700">Poids par poche (kg)</label>
              <input
                type="number"
                name="poids_par_poche"
                value={formData.poids_par_poche}
                onChange={handleInputChange}
                className="w-full px-3 py-2 border rounded"
              />
            </div>
            <div>
              <label className="block text-gray-700">Nombre de bêtes par kilo</label>
              <input
                type="number"
                name="nombre_betes_par_kilo"
                value={formData.nombre_betes_par_kilo}
                onChange={handleInputChange}
                className="w-full px-3 py-2 border rounded"
              />
            </div>
          </>
        )}
        <div className="flex justify-end">
          <button
            type="submit"
            className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
            disabled={!isFormReady || formLocked}
          >
            Ajouter
          </button>
        </div>
      </form>
    </div>
  );
};

export default Mouvements;
