
import React from 'react';
import { Outlet, Link, useNavigate } from 'react-router-dom';

const Layout = ({ handleLogout }) => {

  const navigate = useNavigate();

  const logout = () => {
    handleLogout();
    navigate('/login');
  };

  return (
    <div className="flex min-h-screen">
      <div className="w-1/5 bg-gray-900 text-white p-6 flex flex-col justify-between">
        <div>
          <h2 className="text-4xl mb-6 font-bold">
            <span>OPUS</span> <span className="text-opusYellow">OYSTER</span>
          </h2>
          <ul className="space-y-4">
            <li>
              <Link 
                to="/lots" 
                className="block py-2.5 px-4 rounded transition duration-200 hover:bg-gray-700 hover:text-white"
              >
                Lots d'huitres
              </Link>
            </li>
            <li>
              <Link 
                to="/inventaire" 
                className="block py-2.5 px-4 rounded transition duration-200 hover:bg-gray-700 hover:text-white"
              >
                Inventaire
              </Link>
            </li>
            <li>
              <Link 
                to="/vivier" 
                className="block py-2.5 px-4 rounded transition duration-200 hover:bg-gray-700 hover:text-white"
              >
                Vivier
              </Link>
            </li>
            <li>
              <Link 
                to="/etat-huitres" 
                className="block py-2.5 px-4 rounded transition duration-200 hover:bg-gray-700 hover:text-white"
              >
                Etat des huitres
              </Link>
            </li>
            <li>
              <Link 
                to="/mouvements/ajouter" 
                className="block py-2.5 px-4 rounded transition duration-200 hover:bg-gray-700 hover:text-white"
              >
                Déplacer les huitres
              </Link>
            </li>
            <li>
              <Link 
                to="/mouvements/voir" 
                className="block py-2.5 px-4 rounded transition duration-200 hover:bg-gray-700 hover:text-white"
              >
                Voir les déplacements d'huitres
              </Link>
            </li>
          </ul>
        </div>
        <button
          onClick={logout}
          className="mt-6 bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600"
        >
          Déconnexion
        </button>
      </div>
      <div className="w-4/5 p-6 bg-gray-100">
        <Outlet />
      </div>
    </div>
  );
};

export default Layout;
