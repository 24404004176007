import React, { useEffect, useState } from 'react';
import { fetchEtatHuitresDetail } from './api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const EtatHuitres = () => {
  const [etatHuitres, setEtatHuitres] = useState([]);
  const [viewType, setViewType] = useState('lot');
  const [includeArchived, setIncludeArchived] = useState(false);
  const [selectedLot, setSelectedLot] = useState(null);
  const [selectedType, setSelectedType] = useState(null);

  useEffect(() => {
    const loadEtatHuitres = async () => {
      try {
        const response = await fetchEtatHuitresDetail(includeArchived);
        console.log(response.data);
        setEtatHuitres(Array.isArray(response.data) ? response.data : []);
      } catch (error) {
        console.error('Failed to fetch état huitres:', error);
        toast.error('Échec du chargement de l\'état des huîtres');
      }
    };
    loadEtatHuitres();
  }, [includeArchived]);

  const handleLotClick = (lotNom) => {
    const lotDetails = etatHuitres.filter(item => item.lot_nom === lotNom);
    setSelectedLot({ nom: lotNom, types: lotDetails });
    setSelectedType(null); // Reset the selected type
  };

  const handleTypeClick = (typeHuitre) => {
    const typeDetails = etatHuitres.filter(item => item.type_huitre === typeHuitre);
    setSelectedType({ type: typeHuitre, lots: typeDetails });
    setSelectedLot(null); // Reset the selected lot
  };

  const groupedByLot = etatHuitres.reduce((acc, item) => {
    if (!acc[item.lot_nom]) {
      acc[item.lot_nom] = [];
    }
    acc[item.lot_nom].push(item);
    return acc;
  }, {});

  const groupedByType = etatHuitres.reduce((acc, item) => {
    if (!acc[item.type_huitre]) {
      acc[item.type_huitre] = [];
    }
    acc[item.type_huitre].push(item);
    return acc;
  }, {});

  return (
    <div>
      <h2 className="text-2xl mb-4">État des huîtres</h2>
      <div className="flex mb-4">
        <button
          onClick={() => setViewType('lot')}
          className={`mr-2 px-4 py-2 ${viewType === 'lot' ? 'bg-blue-500 text-white' : 'bg-gray-300'}`}
        >
          Afficher par lot
        </button>
        <button
          onClick={() => setViewType('type')}
          className={`px-4 py-2 ${viewType === 'type' ? 'bg-blue-500 text-white' : 'bg-gray-300'}`}
        >
          Afficher par type
        </button>
      </div>
      <div className="flex mb-4">
        <input 
          type="checkbox" 
          checked={includeArchived}
          onChange={() => {
            setIncludeArchived(!includeArchived);
            setSelectedLot(null);
            setSelectedType(null);
          }}
        />
        <label className="ml-2">Inclure les lots archivés</label>
      </div>
      {viewType === 'lot' ? (
        <div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {Object.keys(groupedByLot).map((lotNom, index) => (
              <div
                key={index}
                className="p-4 border rounded shadow-lg space-y-2 bg-white"
                onClick={() => handleLotClick(lotNom)}
              >
                <h3 className="text-lg font-bold text-primary-dark mb-2">{lotNom}</h3>
              </div>
            ))}
          </div>
          {selectedLot && (
            <div className="mt-6">
              <h3 className="text-xl mb-4">Détails du lot <b>{selectedLot.nom}</b></h3>
              <table className="table-auto w-full border-collapse border border-gray-300">
                <thead>
                  <tr className="bg-gray-200">
                    <th className="border border-gray-300 px-4 py-2">Type</th>
                    <th className="border border-gray-300 px-4 py-2">Quantité Vivier (kg)</th>
                    <th className="border border-gray-300 px-4 py-2">Quantité Marais (kg)</th>
                    <th className="border border-gray-300 px-4 py-2">Quantité Parc (kg)</th>
                    <th className="border border-gray-300 px-4 py-2">Quantité Vente (kg)</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedLot.types.map((type, index) => (
                    <tr key={index}>
                      <td className="border border-gray-300 px-4 py-2 text-center">Numéro {type.type_huitre}</td>
                      <td className="border border-gray-300 px-4 py-2 text-center">{type.quantite_vivier} kg</td>
                      <td className="border border-gray-300 px-4 py-2 text-center">{type.quantite_marais} kg</td>
                      <td className="border border-gray-300 px-4 py-2 text-center">{type.quantite_parc} kg</td>
                      <td className="border border-gray-300 px-4 py-2 text-center">{type.quantite_vente} kg</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      ) : (
        <div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {Object.keys(groupedByType).map((typeHuitre, index) => (
              <div
                key={index}
                className="p-4 border rounded shadow-lg space-y-2 bg-white"
                onClick={() => handleTypeClick(typeHuitre)}
              >
                <h3 className="text-lg font-bold text-primary-dark mb-2">{typeHuitre}</h3>
              </div>
            ))}
          </div>
          {selectedType && (
            <div className="mt-6">
              <h3 className="text-xl mb-4">Détails des huîtres numéro <b>{selectedType.type}</b></h3>
              <table className="table-auto w-full border-collapse border border-gray-300">
                <thead>
                  <tr className="bg-gray-200">
                    <th className="border border-gray-300 px-4 py-2">Nom du lot</th>
                    <th className="border border-gray-300 px-4 py-2">Quantité Vivier (kg)</th>
                    <th className="border border-gray-300 px-4 py-2">Quantité Marais (kg)</th>
                    <th className="border border-gray-300 px-4 py-2">Quantité Parc (kg)</th>
                    <th className="border border-gray-300 px-4 py-2">Quantité Vente (kg)</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedType.lots.map((lot, index) => (
                    <tr key={index}>
                      <td className="border border-gray-300 px-4 py-2 text-center">{lot.lot_nom}</td>
                      <td className="border border-gray-300 px-4 py-2 text-center">{lot.quantite_vivier} kg</td>
                      <td className="border border-gray-300 px-4 py-2 text-center">{lot.quantite_marais} kg</td>
                      <td className="border border-gray-300 px-4 py-2 text-center">{lot.quantite_parc} kg</td>
                      <td className="border border-gray-300 px-4 py-2 text-center">{lot.quantite_vente} kg</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default EtatHuitres;
