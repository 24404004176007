import React, { useState, useEffect } from 'react';
import { fetchLots, fetchMouvementsByLot } from './api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const MovementsList = () => {
  const [lots, setLots] = useState([]);
  const [selectedLot, setSelectedLot] = useState('');
  const [movements, setMovements] = useState([]);
  const [includeArchived, setIncludeArchived] = useState(false);

  const loadLots = async (archived = false) => {
    try {
      const response = await fetchLots(archived);
      setLots(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      console.error('Failed to fetch lots:', error);
      toast.error('Échec du chargement des lots');
    }
  };

  useEffect(() => {
    loadLots();
  }, []);

  const handleLotChange = async (e) => {
    const lotId = e.target.value;
    setSelectedLot(lotId);

    if (lotId) {
      try {
        const response = await fetchMouvementsByLot(lotId);
        console.log(response.data);
        setMovements(Array.isArray(response.data) ? response.data : []);
      } catch (error) {
        console.error('Failed to fetch movements:', error);
        toast.error('Échec du chargement des déplacements');
      }
    } else {
      setMovements([]);
    }
  };

  const handleIncludeArchivedChange = () => {
    setIncludeArchived(!includeArchived);
    loadLots(!includeArchived);
    setSelectedLot('');
    setMovements([]);
  };

  return (
    <div>
      <h2 className="text-2xl mb-4">Historique des déplacements des lots</h2>
      <div className="mb-4">
        <label className="block text-gray-700">Sélectionner un lot</label>
        <select
          value={selectedLot}
          onChange={handleLotChange}
          className="w-full px-3 py-2 border rounded"
        >
          <option value="">Sélectionner un lot</option>
          {lots.map((lot) => (
            <option key={lot.id} value={lot.id}>
              {lot.nom}
            </option>
          ))}
        </select>
      </div>
      <div className="mb-4">
        <input
          type="checkbox"
          checked={includeArchived}
          onChange={handleIncludeArchivedChange}
        />
        <label className="ml-2">Inclure les lots archivés</label>
      </div>
      {movements.length > 0 ? (
        <table className="min-w-full bg-white">
          <thead>
            <tr>
              <th className="py-2">Date de déplacement</th>
              <th className="py-2">Lieu de départ</th>
              <th className="py-2">Lieu d'arrivée</th>
              <th className="py-2">Type/Quantité</th>
              <th className="py-2">Détails des Poches</th>
              <th className="py-2">Numéro de rang</th>
              <th className="py-2">Acheteur</th>
            </tr>
          </thead>
          <tbody>
            {movements.map((movement) => (
              <tr key={movement.id}>
                <td className="border px-4 py-2">
                  {new Date(movement.date_mouvement).toLocaleDateString('fr-FR')}
                </td>
                <td className="border px-4 py-2">{movement.lieu_from}</td>
                <td className="border px-4 py-2">{movement.lieu_to}</td>
                {/* Afficher soit la quantité et le type d'huître, soit les détails des poches */}
                <td className="border px-4 py-2">
                  {movement.type_huitre 
                    ? `${movement.type_huitre} (${movement.quantite || 'N/A'} kg)`
                    : 'N/A'}
                </td>
                <td className="border px-4 py-2">
                  {movement.nombre_poche 
                    ? `${movement.nombre_poche} poches, ${movement.poids_par_poche || 'N/A'} kg/poches, ${movement.nombre_betes_par_kilo || 'N/A'} bêtes/kg`
                    : 'N/A'}
                </td>
                <td
                  className={`border px-4 py-2 ${
                    movement.lieu_to !== 'Parc' && !movement.numero_rang_to ? 'bg-red-400' : ''
                  }`}
                >
                  {movement.lieu_to === 'Parc' ? movement.numero_rang_to || '' : ''}
                </td>
                <td
                  className={`border px-4 py-2 ${
                    movement.lieu_to !== 'Vente' && !movement.vente_acheteur_to ? 'bg-red-400' : ''
                  }`}
                >
                  {movement.lieu_to === 'Vente' ? movement.vente_acheteur_to || '' : ''}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p className="text-gray-500">Aucun mouvement trouvé pour ce lot.</p>
      )}
    </div>
  );
};

export default MovementsList;
