import axios from 'axios';

const api = axios.create({
  baseURL: 'https://opus-oyster.opus-international.fr/api',
});

export const setAuthToken = (token) => {
  if (token) {
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete api.defaults.headers.common['Authorization'];
  }
};

export const login = (email, password) => {
  return api.post('/login', { email, password });
};

// Inventaire API
export const fetchInventaire = () => {
  return api.get('/inventaire');
};

export const updateInventaire = async (numeroRang, updatedTypeLotIds) => {
  return api.put(`/inventaire/${numeroRang}`, { type_lot_ids: updatedTypeLotIds });
};

export const addInventaire = (data) => {
  return api.post('/inventaire', data);
};

export const fetchEtatHuitresDetail = (includeArchived = false) => {
  return api.get(`/etat-huitres/detail?includeArchived=${includeArchived}`);
};


export const fetchTypesByRang = (numeroRang) => {
  return api.get(`/inventaire/rang/${numeroRang}`);
};

export const fetchLotsInRang = (numero_rang) => {
  return api.get(`/inventaire/${numero_rang}/lots`);
};

export const fetchTypesInRang = (numeroRang) => {
  return api.get(`/inventaire/${numeroRang}/types`);
};

export const fetchLots = (includeArchived = false) => {
  return api.get(`/lots?includeArchived=${includeArchived}`);
};


export const addLot = (lot) => {
  return api.post('/lots', lot);
};

export const updateLot = (id, lot) => {
  return api.put(`/lots/${id}`, lot);
};

export const deleteLot = (id) => {
  return api.delete(`/lots/${id}`);
};

export const fetchTypesByLot = (lotId) => {
  return api.get(`/lots/${lotId}/types`);
};

// Mouvements API
export const fetchMouvements = () => {
  return api.get('/mouvements');
};

export const archiveLot = (id) => {
  return api.put(`/lots/${id}/archive`);
};

export const unarchiveLot = (id) => {
  return api.put(`/lots/${id}/unarchive`);
};

export const addMouvement = (mouvement) => {
  return api.post('/mouvements', mouvement);
};

export const fetchMouvementsByLot = (lot_id) => {
  return api.get(`/mouvements/${lot_id}`);
};


// Vivier API
export const fetchVivier = () => {
  return api.get('/vivier');
};

export const addVivierEntry = (entry) => {
  return api.post('/vivier', entry);
};

export const updateVivierEntry = (id, entry) => {
  return api.put(`/vivier/${id}`, entry);
};

export const deleteVivierEntry = (id) => {
  return api.delete(`/vivier/${id}`);
};

export const fetchEtatHuitresMarais = (includeArchived = false) => {
  return api.get(`/etat-huitres/marais?includeArchived=${includeArchived}`);
};

export const fetchEtatHuitresMaraisParType = (includeArchived = false) => {
  return api.get(`/etat-huitres/marais/types?includeArchived=${includeArchived}`);
};

export const fetchRemainingQuantity = (typeId) => {
  return api.get(`/types-lots/${typeId}/remaining-quantity`)
}
// api.js
export const fetchTypesByLotId = (lot_id) => {
  return api.get(`/types_lots/by-lot/${lot_id}`);
};


export const fetchLotsByTypeHuitre = (type_huitre) => {
  return api.get(`/types_lots/by-type/${type_huitre}`);
};

export const fetchAcheteurs = () => {
  return api.get('/acheteurs');
};

export default api;
